.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem !important;
  height: 3.2rem !important;
  flex-shrink: 0;
  background: #f2f2f2 !important;
  border-bottom: 1px solid #bbbbbb;
}
.body{
  flex-grow: 1;
  overflow: auto;
  min-height: 2em;
}
.footer{
  height: 3.2rem !important;
  padding: 0 1rem !important;
  flex-shrink: 0;
  background: #f2f2f2 !important;
  border-top: 1px solid #bbbbbb;
  display: flex;
  align-items: center;
  justify-content: space-around;
  .box {
    display: flex;
    flex-flow: column;
    align-items: center;
    cursor: pointer;
    .icon {
      font-size: 1.4rem;
    }
    .text {
      font-size: 0.8rem;
    }
  }
}
